<script setup>
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useHarbourStore } from '@/stores/harbour-store';

const harbourStore = useHarbourStore();
const { profilePictures } = storeToRefs(harbourStore);
const props = defineProps({
  users: {
    type: Array,
    required: false,
  },
  displayText: {
    type: String,
  },
  stage: {
    type: String,
    required: false
  },
  showNames: {
    type: Boolean,
    default: false,
  },
});

const statusColors = {
  pending: '#F2DF9D',
  sent_back: '#F2DF9D',
  approved: '#CDE6E6',
  default: '#F5F5F5',
};

const getStyle = computed(() => {
  const color = props.stage ? statusColors[props.stage] : 'transparent';
  return {
    backgroundColor: color,
  }
});

const getProfileImageUrl = computed(() => (email) =>{
  const profile = profilePictures.value?.find((pic) => pic.email === email);
  return profile?.profileImageUrl;
});

const getUserNames = computed(() => {
  return props.users?.map((user) => user.name).join(', ');
});

const getInitial = computed(() => (user) => {
  if (user.name) return user.name[0];
  else if (user.email) return user.email[0];
  return '';
})

const getUserNamesAsList = computed(() => {
  return props.users?.map((user) => `${user.name} (${user.email})`).join('\n');
});
</script>

<template>
  <div class="pill-container" :style="getStyle">
    <div class="user-images" v-if="props.users">
      <div v-for="user in users" :key="user.email">
        <div class="user-image" v-if="(getProfileImageUrl(user.email) || getInitial(user))">
          <img v-if="getProfileImageUrl(user.email)" :src="getProfileImageUrl(user.email)" />
          <div v-else>{{ getInitial(user) }}</div>
        </div>
      </div>
    </div>

    <div class="current-status" v-if="!showNames">
      {{ displayText }}
    </div>

    <div v-else class="current-status user-names" :title="getUserNamesAsList">
      {{ getUserNames }}
    </div>

  </div>
</template>

<style scoped>
.user-names {
  max-width: 100%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: hover;
  cursor: default;
}
.user-image {
  border-radius: 50%;
  overflow: hidden;
  width: 24px;
  height: 24px;
  background-color: #CCC;
  border: 1px solid #BBBBBB99;
}
.user-images {
  display: flex;
  margin-right: 10px;
}
.user-image {
  position: relative;
  margin-left: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.current-status {
  white-space: nowrap;
}
.pill-container {
  border-radius: 40px;
  padding: 4px 8px;
  display: flex;
  background-color: #DFDFDF;
  font-size: 12px;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}
</style>